.cms-page-view.cms-about-us {
  .page-main {
    text-align: center;

    .feature-list {
      list-style: none;
      margin: 64px 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      text-align: center;

      &.bottom {
        li:nth-child(2) {
          width: 100%;
          margin: 0 0 0 -1px;
          border-top: 0;

          @include breakpoint(max-width $mobile) {
            order: 3;
          }

          @include breakpoint($mobile) {
            width: 33.33%;
            margin: -1px 0 0 -1px;
            border-top: 1px dashed $border;
          }
        }
      }

      li {
        border: 1px dashed $border;
        margin: -1px 0 0 -1px;
        width: 50%;
        padding: 14px;

        @include breakpoint($mobile) {
          width: 33.33%;
          padding: 20px;
        }

        h3 {
          @include fontSize(13px);
          font-weight: 600;
          font-family: $sourceSans;
          margin: 10px 0 15px;
          line-height: 1.4;
          text-align: center;

          @include breakpoint($mobile) {
            @include fontSize(16px);
          }
        }

        img {
          width: 90px;
        }
      }
    }
  }
}

.about-us {
  &__intro {
    font-weight: 600;
    font-family: $sourceSans;

    @media only screen and (min-width: 1040px) {
      margin: 0 -60px;
    }
  }
}

.about-store {
  display: block;
  width: 100%;
  margin: 64px 0;
}

.video-block {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin: 32px 0;
  
  @include breakpoint($mobile) {
    margin: 64px 0;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}