@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

@font-face {
  font-family: "bg-reg";
  src: url("../fonts/brandon_grotesque_reg.eot"),
  url("../fonts/brandon_grotesque_reg.woff2") format("woff2"),
  url("../fonts/brandon_grotesque_reg.woff") format("woff"),
  url("../fonts/brandon_grotesque_reg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bg-bold";
  src: url("../fonts/brandon_grotesque_bold.eot"),
  url("../fonts/brandon_grotesque_bold.woff2") format("woff2"),
  url("../fonts/brandon_grotesque_bold.woff") format("woff"),
  url("../fonts/brandon_grotesque_bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

$brandonReg: "bg-reg", sans-serif;
$brandonBold: "bg-bold", sans-serif;
$sourceSans: "Source Sans Pro", sans-serif;
$questrial: "Questrial", sans-serif;

// Convert px to rem
@mixin fontSize($pix) {
  $remVal: $pix / 10px;
  font-size: $remVal + rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: none;
}

.theme-pearl.store-view-default {
  letter-spacing: 1px;
}
