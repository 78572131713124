@include breakpoint($desktop) {
  .navigation .megamenu.level-top-sectioned {
    .submenu .columns-group li {
      padding: 0 !important;

      & > a {
        padding: 0 10px 5px;
        text-align: center;

        span {
          text-transform: none;
        }
      }
    }

    .level0.submenu {
      & > li {
        max-width: 600px;
        margin: 0 auto;
        display: flex;
        padding: 0 20px;
      }

      &.sectioned.has-menu-block .columns-group {
        width: 100% !important;
      }

      .menu-block.block-container ul li {
        float: left;
        width: 33.33%;

        a {
          text-align: center;
          text-transform: uppercase;
        }
      }
    }
  }
}

body.theme-pearl:not(.mobile-nav) .nav-sections .navigation ul li.level0 .parent > a {
  padding: 0;
}

.theme-pearl  .navigation .megamenu.level-top-sectioned {
  .submenu [data-has-children] > a span:last-child {
    @include fontSize(14px);
    border: 0;
    padding: 0;
  }

  .submenu .level1 ul.submenu {
    display: none !important;
  }
}

.navigation .has-menu-block .menu-block {
  display: block !important; // overwrite welt important
}

#mega-featured-categories {
  padding: 0;
  display: block !important; // overwrite welt important

  @include breakpoint($desktop) {
    margin: 0 0 15px;
  }

  li {
    a {
      @include fontSize(14px);
      color: #232323 !important;
      padding: 0 0 20px;

      &:hover {
        color: $black !important;
      }

      @include breakpoint($desktop) {
        padding: 0 0 5px;
      }
    }
  }

  .ui-menu-divider {
    @include breakpoint(max-width $desktop) {
      display: none;
    }
  }
}

.megamenu .ui-menu-item.all-category {
  @include breakpoint(max-width $desktop) {
    padding: 10px 0 !important;
    width: 100%;

    a {
      padding-left: 0 !important;
      overflow: hidden;
      text-indent: -31px;
    }
  }
}

.nav-sections .nav-sections-items .section-item-content .navigation .megamenu.level0 .level1.parent  {
  margin-top: 5px;
}

nav.navigation .megamenu.level0 .submenu {
  padding: 0 0 20px;

  @include breakpoint($desktop) {
    padding: 0;
  }
}
