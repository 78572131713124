.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir="rtl"] & {
    float: right;
  }

  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.theme-pearl.store-view-default {
  .slick-next,
  .slick-prev {
    border-radius: 3px;
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    left: 15px;
    text-indent: 100%;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    background-color: $white;
    font-size: 0;
    z-index: 10;
    border: 0;
    opacity: 0.75;
    margin-top: -20px;

    &::before {
      position: absolute;
      top: 8px;
      left: 7px;
      z-index: 1;
      width: 26px;
      height: 25px;
      content: "";
      background-color: $black;
      opacity: 0.8;
      border-top: 0;
      mask-image: url("../WeltPixel_OwlCarouselSlider/images/arrow.svg");
    }

    &:hover {
      border: 0;
    }
  }
}

.theme-pearl.store-view-default .slick-next {
  left: auto;
  right: 15px;

  &::before {
    transform: rotate(180deg);
  }
}
