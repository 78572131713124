.sidebar {
  .swatch-option {
    border: 0;
  }
}

.popular-brands {
  margin: 0 0 28px;

  @include breakpoint($mobile) {
    margin: 25px 0 28px;
  }

  .block-subtitle {
    @include fontSize(12px);
  }

  ul {
    display: flex;
    padding: 0;
    margin: 14px 0 0;
    list-style: none;
    flex-wrap: wrap;

    li {
      margin: 6px 6px 0 0;

      a {
        @include fontSize(14px);
        background: #00C5C5;
        color: $white;
        padding: 5px 12px;
        border-radius: 25px;
        display: block;
        transition: 0.3s ease background;

        &:hover {
          background: darken(#00C5C5, 5%);
        }
      }
    }
  }
}

.block-reorder {
  display: none;
}

.filter-options-item {
  &.Brands {
    display: none;
  }
}

.categorypath-all-brands {
  .filter-options-item {
    &.Category {
      display: none;
    }

    &.Brands {
      display: block;
    }
  }
}

#layered-filter-block .filter-content .filter-current ol {
  padding: 5px 10px;

  @include breakpoint($desktop) {
    padding: 5px 0;
  }
}

.toolbar.toolbar-products {
  padding: 10px 0;
}