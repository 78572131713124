.privacy-policy-content {
  & > ol {
    list-style: none;
    padding-left: 0 !important;
  }

  ol ol {
    counter-reset: item;
    list-style: none;

    li::before {
      display: inline-block;
      content: counters(item, ".") " ";
      counter-increment: item;
      width: 45px;
      margin-left: -45px;
      text-align: center;
      font-weight: bold;
    }
  }
}

.terms-title {
  margin-top: 0;
}

@include breakpoint(max-width $desktop - 1px) {
  .privacy-policy {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    left: 0;

    &-content {
      order: 2;
    }
  }
}
