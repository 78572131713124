// Grid breakpoints
$mobileSmall: 428px;
$mobile: 768px;
$tablet: 960px;
$desktop: 1040px;
$desktop-large: 1400px;

@include breakpoint(max-width $mobile) {
  .mobile-hide {
    display: none;
  }
}

.theme-pearl.store-view-default .page-main {
  padding: 10px;

  @include breakpoint($mobile) {
    padding: 20px 15px;
  }
}

.breadcrumbs .items {
  padding: 0 20px;
}

.display-block {
  display: block;
}
