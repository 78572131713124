.brand-fields {
  display: flex;
  justify-content: space-between;

  .field {
    width: 47.5%;
    margin: 0 0 20px;

    input,
    select {
      background-color: $white-7;
      border: 0;
    }

    select {
      color: #bbb !important; // overwrite important
    }
  }
}
