.store-view-default {
  &.cms-page-view,
  &.partexchange-index-index {
    .breadcrumbs {
      display: none;
    }

    .page-main {
      margin: 35px auto;
      width: 100%;
      letter-spacing: 0;
      font-family: sans-serif;

      h1 {
        @include fontSize(24px);
        font-family: $brandonBold;
        line-height: 1.4;
        margin: 0 0 35px;
        letter-spacing: 0;
        font-weight: 500;
        text-transform: uppercase;
      }

      h2,
      h3 {
        line-height: 1.4;
        font-family: sans-serif;
        font-weight: 600;
        letter-spacing: 0;
      }

      h2 {
        @include fontSize(18px);
      }

      h3 {
        @include fontSize(14px);
      }

      p {
        @include fontSize(12px);
        line-height: 1.4;
        font-family: sans-serif;
        margin: 13px 0;
        text-align: justify;

        a {
          text-decoration: underline;
          word-break: break-word;
        }
      }

      table p {
        text-align: initial;
      }

      ol,
      ul {
        @include fontSize(12px);
        padding: 0 0 0 25px;
        margin: 20px 0;
        text-align: justify;

        h2,
        h3,
        h4,
        h5 {
          text-align: left;
        }

        li {
          margin: 0 0 5px;

          &:last-child {
            margin: 0;
          }
        }
      }

      .block-collapsible-content ul {
        padding-left: 0;
        text-align: left;
      }

      .accordion.accordion-border .acctitle {
        line-height: 1.5;
        padding-top: 12px;
        padding-bottom: 12px;

        i {
          line-height: 3.2;
        }
      }

      .table {
        @include fontSize(12px);
      }
    }
  }

  .cms-part-exchange-a-watch,
  .partexchange-index-index {
    .exchange-part-form {
      margin: 40px auto 0;
      background: $white-9;
      padding: 15px 4% 35px;

      @include breakpoint($mobile) {
        max-width: 80%;
      }

      .label {
        @include fontSize(13px);
      }
    }
  }

  #maincontent {
    &.page-main > .page-title-wrapper .page-title {
      display: block;
      text-align: center;
      margin: 15px 0 25px;
      line-height: 1.2;
    }
  }

  .rolex-serials {
    max-width: 980px;
    margin: 0 auto;

    p strong {
      font-weight: 600;
    }

    h3 {
      margin: 20px 0 15px;

      @include breakpoint($mobile) {
        margin: 35px 0 25px;
      }    
    }

    table {
      margin: 0 0 20px;

      @include breakpoint($mobile) {
        margin: 0 0 35px;
      }

      td {
        width: 50%;
      }
    }
  }

  #ReviewsWidget .Tabs {
    &__tab {
      display: none;

      &:first-child {
        display: flex;
      }
    }
  }

  // Instashop
  .fs-normal-timeline .fs-entry-container {
    width: 50%;
    padding-top: 50%;

    @include breakpoint($mobile) {
      width: 33.3%;
      padding-top: 33.3%;
    }

    div.fs-timeline-entry {
      margin: 5px;
    }
  }

  .fs-wrapper div.fs-timeline-entry {
    margin: 5px;
  }

  // Customer Reviews CMS Page
  .store-view-default.cms-customer-reviews .page-main {
    padding: 10px 30px;
  }
}

.fixed-table {
  @include breakpoint($mobile) {
    table-layout: fixed;
  }

  .long-text {
    p {
      width: 180px;

      @include breakpoint($mobile) {
        width: auto;
      }
    }
  }
}

.table-responsive > .table.fixed-table {
  th,
  td {
    white-space: unset;
  }
}
