.checkout-container .payment-option-content .form-discount {
  .actions-toolbar .primary .action {
    border: 0;
    height: 40px;
    background: $black;
    color: $white;
    margin: 0 0 0 5px;

    span {
      color: $white;
    }
  }

  .input-text {
    height: 40px;
  }
}

.methods-shipping {
  thead,
  .col-carrier {
    display: none;
  }

  .table-checkout-shipping-method {
    width: 100%;
  }
}

.checkout-index-index {
  .usp-bar {
    display: none;
  }
}

.opc-estimated-wrapper {
  margin-top: 15px;
}

.page-main .opc-wrapper {
  .form-shipping-address,
  .methods-shipping {
    background: $white;
  }
}
