#wpn-lightbox-content-v3 {
  background: none !important; // overwrite welt important

  .weltpixel_newsletter_signup_section {
    margin: 0;
    width: 100%;
  }
}

.weltpixel_newsletter__sub-title {
  font-family: $sourceSans;
  text-transform: uppercase;
  font-weight: 600;
  margin: -25px 0 25px;
}

.wp_newsletter_trigger_button {
  top: auto;
  bottom: 28%;
  margin: 0 0 20px;
}

.btt-button {
  right: 45px;
}
