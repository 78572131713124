.product-carousel {
  &.block-margin.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  &__heading {
    @include fontSize (22px);
    font-family: $questrial;
    margin: 20px 0 40px;
  }

  .product-item-name,
  .product-item-name a {
    @include fontSize(12px);
    text-transform: uppercase;
    color: $dark-grey;
    font-family: $questrial;
    line-height: 1.7;
    letter-spacing: 1px;
  }

  .product-item-actions {
    display: none;
  }

  .product-item .price-box {
    .price {
      @include fontSize(16px);
      font-family: $sourceSans;
    }
  }

  .owl-carousel {
    .owl-item img {
      margin-top: 0;
    }

    &.products .owl-stage-outer {
      padding: 0;
    }
  }

  .owl-theme .owl-controls .owl-nav {
    .owl-prev,
    .owl-next {
      background: $white-1 !important;
      opacity: 0.6;
    }

    [class*="owl-"] {
      top: 32%;
    }
  }

  .old-price {
    display: block;
  }
}

.block,
.product-carousel {
  .owl-carousel .owl-stage-outer,
  .products-grid .product-item .product-item-info {
    padding: 0;
  }

  .products {
    margin: 0;
  }
}

.catalog-product-view .grid.products-grid .owl-item .product-item {
  width: 100% !important;
  margin-left: 0;
}
