// copied over styles
.part-exchange-product-popup {
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  display: none;
  height: 100%;
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  margin: 0;

  &.modal-custom .action-close {
    margin: 5px;

    &::before {
      padding: 10px;
    }
  }

  & > div {
    background-color: $white;
    box-shadow: 10px 10px 60px $grey-5;
    display: inline-block;
    max-height: 100%;
    max-width: 700px;
    vertical-align: middle;
    width: 100%;
    position: relative;
    padding: 40px 3%;
    overflow-y: auto;
    overflow-x: hidden;

    @include breakpoint($mobile) {
      margin-top: 1.5%;
      max-height: 95%;
    }
  }
}

.part-exchange-product-popup,
.enquire-form {
  &__heading {
    @include fontSize(19px);
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 0 0 10px;
    margin: 20px 0;
  }

  &__title {
    margin: 0 0 5px;
  }

  .custom-actions-toolbar {
    margin: 0 0 30px;
  }

  &__product-name {
    display: block;
    text-align: left;
  }

  .field {
    text-align: left;
  }

  input[type="checkbox"] {
    top: 0;
  }
}

.actions {
  .action.primary {
    line-height: 1;
    padding: 13px 16px;
    font-size: 1.8rem;
  }
}

.custom-primary {
  @include fontSize(16px);
  font-weight: bold;
}

.note {
  color: $green;
}

.error,
.valueError {
  color: $red;
}
