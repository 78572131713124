.find-watch {
  background: url("../images/content/find-watch-bg.png") center no-repeat;
  background-size: cover;
  padding: 15px 10px;
  width: 160px;
  display: flex;
  align-items: center;

  @include breakpoint($mobileSmall) {
    width: 240px;
    padding: 35px 25px;
  }

  &__inner {
    background: $white;
    padding: 24px 16px;
    text-align: center;
    margin: 0 auto;

    h4 {
      @include fontSize(15px);
      font-family: $sourceSans;
      font-weight: 600;
      margin: 8px 0 20px;
    }

    p {
      @include fontSize(14px);
      margin: 0 0 22px;
      letter-spacing: 0.2px;
    }
  }
}

.custom-slider--find-watch {
  display: flex;

  .owl-carousel {
    width: calc(100% - 160px);

    @include breakpoint($mobileSmall) {
      width: calc(100% - 240px);
    }
  }

  .owl-controls {
    display: none;
  }
}

.product-item {
  .find-watch {
    width: 100%;
    height: 100%;
  }

  &.find-watch-product {
    max-width: 400px;
    margin: 0 auto;
    background: none;
    box-shadow: none;
  }
}
