.enquire-form {
  .contact-form-popup {
    @include breakpoint(max-width $desktop) {
      width: 100%;
    }
  }

  &__product {
    margin: 0 0 20px;
    padding: 0 25px 0 0 !important;
    display: none;
    text-align: center;

    @include breakpoint($desktop) {
      display: block;
    }

    &-heading {
      @include fontSize(16px);
      text-align: center;
      font-weight: 600;
      margin: 10px 0 15px;
    }

    &-price {
      @include fontSize(20px);
      font-weight: 600;
    }
  }

  .modal-popup {
    left: 0;

    &.modal-slide {
      @include breakpoint(max-width $mobile) {
        left: 0;
      }
    }

    .modal-inner-wrap {
      width: 100%;
      max-width: 100%;

      @include breakpoint($desktop) {
        max-width: 800px;
      }

      &[class] {
        background: $white;
      }
    }

    .modal-header {
      padding: 0;
    }

    .modal-title {
      @include fontSize(22px);
      margin: 0;
      padding: 15px 35px 10px 25px;
      text-align: center;
      border: 0;
    }

    .primary {
      width: 100%;
      margin: 0 0 15px;
    }

    #submit-enquire {
      padding: 10px;
      width: 100%;
    }
  }

  .fieldset {
    margin: 0 0 20px;
  }
}

#popup-modal {
  display: none;
}

._show #popup-modal {
  display: block;
}
