.page-wrapper .page-footer {
  margin: 0;

  .footer.content {
    .footer-v3 {
      text-align: left;
      padding: 0 0 20px;

      @include breakpoint($mobile) {
        padding-top: 20px;
      }
    }

    .icon-facebook {
      background-image: url("../images/icons/facebook.svg");
      background-color: #3B5998;
    }

    .icon-youtube {
      background-image: url("../images/icons/youtube.svg");
      background-color: #C4302B;
    }

    .icon-instagram {
      background-image: url("../images/icons/instagram.svg");
      background-color: #3F729B;
    }

    .icon-twitter {
      background-image: url("../images/icons/twitter.svg");
      background-color: #00ACEE;
      background-position: center top 9px;
    }

    .social-icons {
      border: 0;

      i,
      em {
        background-repeat: no-repeat;
        background-position: center;
        height: 38px;
        background-size: 25px;
        display: block;
        position: relative;
        transition: all 0.3s ease;

        &::before {
          content: "";
        }
      }

      &:hover em:first-child {
        margin-top: -38px;
      }
    }

    &.content {
      .footer-v3-list {
        padding-right: 0;
      }
    }
  }
}

.newsletter-subscribe {
  margin: 10px 0 0;

  button {
    padding: 7px 11px;
    border: 1px solid $white !important;
  }
}

.footer-payment {
  text-align: center;
  padding: 0 0 5px;
  margin-top: -1px;
  background: $white;

  @include breakpoint($mobile) {
    border-top: 1px solid #eee;
    padding: 5px 0 20px;
  }

  &__heading {
    @include fontSize(14px);
    font-family: $sourceSans;
    font-weight: 600;
    text-transform: uppercase;
    margin: 5px 0;
  }

  &__inner {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @include breakpoint($mobile) {
      flex-direction: row;
    }
  }

  &__col {
    padding: 10px 15px 0;
    margin: 10px 0 0;
    border-top: 1px solid #eee;

    @include breakpoint($mobile) {
      width: 50%;
      border-top: 0;
      padding: 0 15px;
    }

    &:last-child {
      @include breakpoint($mobile) {
        border-left: 1px solid #eee;
      }
    }
  }
}

.footer-copy {
  text-align: center;
  margin: 40px 0 0;
}

.page-wrapper .page-footer .footer.content .footer-v3 h4.no-padding-mob {
  padding-top: 10px;
  margin-bottom: 10px;
}