@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@media (max-width: 768px) {
  .mobile-hide {
    display: none; } }

.theme-pearl.store-view-default .page-main {
  padding: 10px; }
  @media (min-width: 768px) {
    .theme-pearl.store-view-default .page-main {
      padding: 20px 15px; } }

.breadcrumbs .items {
  padding: 0 20px; }

.display-block {
  display: block; }

@font-face {
  font-family: "bg-reg";
  src: url("../fonts/brandon_grotesque_reg.eot"), url("../fonts/brandon_grotesque_reg.woff2") format("woff2"), url("../fonts/brandon_grotesque_reg.woff") format("woff"), url("../fonts/brandon_grotesque_reg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "bg-bold";
  src: url("../fonts/brandon_grotesque_bold.eot"), url("../fonts/brandon_grotesque_bold.woff2") format("woff2"), url("../fonts/brandon_grotesque_bold.woff") format("woff"), url("../fonts/brandon_grotesque_bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: none; }

.theme-pearl.store-view-default {
  letter-spacing: 1px; }

main[id="maincontent"] .wp-button {
  border: 1px solid #000;
  background-color: transparent;
  text-shadow: none;
  margin: 0;
  text-transform: none; }
  main[id="maincontent"] .wp-button span {
    color: #000; }
  main[id="maincontent"] .wp-button:hover {
    background: #000;
    border: 1px solid #000; }
    main[id="maincontent"] .wp-button:hover span {
      color: #fff; }

main[id="maincontent"] .price-container .price-including-tax + .price-excluding-tax {
  display: none; }

.theme-pearl.store-view-default .action.primary {
  font-size: 1.6rem; }
  .theme-pearl.store-view-default .action.primary * {
    font-size: 1.6rem; }

.page-wrapper .page-footer {
  margin: 0; }
  .page-wrapper .page-footer .footer.content .footer-v3 {
    text-align: left;
    padding: 0 0 20px; }
    @media (min-width: 768px) {
      .page-wrapper .page-footer .footer.content .footer-v3 {
        padding-top: 20px; } }
  .page-wrapper .page-footer .footer.content .icon-facebook {
    background-image: url("../images/icons/facebook.svg");
    background-color: #3B5998; }
  .page-wrapper .page-footer .footer.content .icon-youtube {
    background-image: url("../images/icons/youtube.svg");
    background-color: #C4302B; }
  .page-wrapper .page-footer .footer.content .icon-instagram {
    background-image: url("../images/icons/instagram.svg");
    background-color: #3F729B; }
  .page-wrapper .page-footer .footer.content .icon-twitter {
    background-image: url("../images/icons/twitter.svg");
    background-color: #00ACEE;
    background-position: center top 9px; }
  .page-wrapper .page-footer .footer.content .social-icons {
    border: 0; }
    .page-wrapper .page-footer .footer.content .social-icons i,
    .page-wrapper .page-footer .footer.content .social-icons em {
      background-repeat: no-repeat;
      background-position: center;
      height: 38px;
      background-size: 25px;
      display: block;
      position: relative;
      transition: all 0.3s ease; }
      .page-wrapper .page-footer .footer.content .social-icons i::before,
      .page-wrapper .page-footer .footer.content .social-icons em::before {
        content: ""; }
    .page-wrapper .page-footer .footer.content .social-icons:hover em:first-child {
      margin-top: -38px; }
  .page-wrapper .page-footer .footer.content.content .footer-v3-list {
    padding-right: 0; }

.newsletter-subscribe {
  margin: 10px 0 0; }
  .newsletter-subscribe button {
    padding: 7px 11px;
    border: 1px solid #fff !important; }

.footer-payment {
  text-align: center;
  padding: 0 0 5px;
  margin-top: -1px;
  background: #fff; }
  @media (min-width: 768px) {
    .footer-payment {
      border-top: 1px solid #eee;
      padding: 5px 0 20px; } }
  .footer-payment__heading {
    font-size: 1.4rem;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    margin: 5px 0; }
  .footer-payment__inner {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .footer-payment__inner {
        flex-direction: row; } }
  .footer-payment__col {
    padding: 10px 15px 0;
    margin: 10px 0 0;
    border-top: 1px solid #eee; }
    @media (min-width: 768px) {
      .footer-payment__col {
        width: 50%;
        border-top: 0;
        padding: 0 15px; } }
    @media (min-width: 768px) {
      .footer-payment__col:last-child {
        border-left: 1px solid #eee; } }

.footer-copy {
  text-align: center;
  margin: 40px 0 0; }

.page-wrapper .page-footer .footer.content .footer-v3 h4.no-padding-mob {
  padding-top: 10px;
  margin-bottom: 10px; }

.theme-pearl div.page-wrapper .page-header-v4 .page-header .header_right label {
  padding-right: 0 !important; }

.theme-pearl div.page-wrapper .page-header-v4 .page-header .header.content .logo {
  width: auto;
  z-index: 10; }
  @media (max-width: 768px) {
    .theme-pearl div.page-wrapper .page-header-v4 .page-header .header.content .logo {
      width: 190px; } }
  .theme-pearl div.page-wrapper .page-header-v4 .page-header .header.content .logo img {
    display: block;
    width: auto;
    height: auto;
    margin: 4px auto 0; }
    @media (min-width: 768px) {
      .theme-pearl div.page-wrapper .page-header-v4 .page-header .header.content .logo img {
        margin: 0 0 0 -100px;
        max-width: 240px; } }
    @media (min-width: 1040px) {
      .theme-pearl div.page-wrapper .page-header-v4 .page-header .header.content .logo img {
        margin: 0 auto; } }

.header.panel > .header.links {
  display: none;
  margin: 8px 0 0 !important; }
  @media (min-width: 1040px) {
    .header.panel > .header.links {
      display: flex; } }
  .header.panel > .header.links .newsletter-btn {
    order: 3;
    margin-right: 0 !important; }
  .header.panel > .header.links > li {
    margin: 0 22px 0 0 !important;
    letter-spacing: 0;
    color: #656565; }
    .header.panel > .header.links > li.authorization-link::after {
      margin: 0 -15px 0 2px; }
    .header.panel > .header.links > li a {
      text-transform: none;
      color: #00C5C5; }
    .header.panel > .header.links > li::after {
      text-transform: none; }
    .header.panel > .header.links > li.customer-welcome {
      margin: -1px 14px 0 -9px !important; }

.page-wrapper .header.content {
  padding-top: 14px !important;
  padding-bottom: 14px !important; }
  @media (min-width: 768px) {
    .page-wrapper .header.content {
      padding: 20px 0 !important; } }

.compare.wrapper {
  display: none; }

.usp-bar {
  background: #000;
  text-align: center;
  transition: 0.3s ease opacity; }
  .usp-bar__slider {
    margin: 0 auto;
    max-width: 86%;
    padding: 0 35px;
    color: #fff; }
    @media (min-width: 1040px) {
      .usp-bar__slider {
        max-width: 1140px;
        padding: 0 15px; } }
  .usp-bar .owl-carousel .owl-item {
    font-size: 1.3rem;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px; }
  @media (min-width: 1040px) {
    .usp-bar .owl-carousel .owl-stage {
      width: 100% !important;
      display: flex;
      justify-content: space-between; } }
  .usp-bar .owl-carousel .owl-stage-outer {
    padding: 5px 0; }
  .usp-bar .owl-theme .owl-controls .owl-nav [class*="owl-"] {
    background: #000; }
    .usp-bar .owl-theme .owl-controls .owl-nav [class*="owl-"]::before, .usp-bar .owl-theme .owl-controls .owl-nav [class*="owl-"]::after {
      background: #fff;
      width: 20px;
      height: 20px; }
    .usp-bar .owl-theme .owl-controls .owl-nav [class*="owl-"].owl-prev {
      left: -10px; }
    .usp-bar .owl-theme .owl-controls .owl-nav [class*="owl-"].owl-next {
      right: -10px; }
  .usp-bar .owl-thumbs {
    display: none; }

.usp-hidden {
  opacity: 0; }

.page-header-v4 .block-search input {
  opacity: 0;
  padding-right: 35px !important; }
  @media (min-width: 1040px) {
    .page-header-v4 .block-search input {
      opacity: 1; } }

.page-header-v4 .block-search label.active + .control input {
  opacity: 1; }

.page-wrapper .page-header * {
  text-transform: none;
  letter-spacing: 0.7px; }

.page-wrapper .page-header .panel.header ul.header.links li::after {
  margin: 0 -13px 0 4px; }

.theme-pearl .page-wrapper .page-header .panel.header ul.header.links li > a {
  color: #00C5C5; }
  .theme-pearl .page-wrapper .page-header .panel.header ul.header.links li > a:hover {
    color: #00C5C5 !important; }
  .theme-pearl .page-wrapper .page-header .panel.header ul.header.links li > a.wp_newsletter_trigger {
    color: #000;
    border: 1px solid #000;
    padding: 1px 5px; }
    .theme-pearl .page-wrapper .page-header .panel.header ul.header.links li > a.wp_newsletter_trigger:hover {
      color: #000 !important;
      text-decoration: none; }

.weltpixel_quickview-catalog_product-view .usp-bar {
  display: none; }

.weltpixel_quickview-catalog_product-view .page-title-wrapper {
  margin: 15px 0 0; }

.page-header .header.panel {
  padding-left: 15px;
  padding-right: 15px; }

@media (max-width: 428px) {
  .page-header .minicart-wrapper .action.showcart .counter.qty {
    display: none; } }

@media (min-width: 1040px) {
  .navigation .megamenu.level-top-sectioned .submenu .columns-group li {
    padding: 0 !important; }
    .navigation .megamenu.level-top-sectioned .submenu .columns-group li > a {
      padding: 0 10px 5px;
      text-align: center; }
      .navigation .megamenu.level-top-sectioned .submenu .columns-group li > a span {
        text-transform: none; }
  .navigation .megamenu.level-top-sectioned .level0.submenu > li {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    padding: 0 20px; }
  .navigation .megamenu.level-top-sectioned .level0.submenu.sectioned.has-menu-block .columns-group {
    width: 100% !important; }
  .navigation .megamenu.level-top-sectioned .level0.submenu .menu-block.block-container ul li {
    float: left;
    width: 33.33%; }
    .navigation .megamenu.level-top-sectioned .level0.submenu .menu-block.block-container ul li a {
      text-align: center;
      text-transform: uppercase; } }

body.theme-pearl:not(.mobile-nav) .nav-sections .navigation ul li.level0 .parent > a {
  padding: 0; }

.theme-pearl .navigation .megamenu.level-top-sectioned .submenu [data-has-children] > a span:last-child {
  font-size: 1.4rem;
  border: 0;
  padding: 0; }

.theme-pearl .navigation .megamenu.level-top-sectioned .submenu .level1 ul.submenu {
  display: none !important; }

.navigation .has-menu-block .menu-block {
  display: block !important; }

#mega-featured-categories {
  padding: 0;
  display: block !important; }
  @media (min-width: 1040px) {
    #mega-featured-categories {
      margin: 0 0 15px; } }
  #mega-featured-categories li a {
    font-size: 1.4rem;
    color: #232323 !important;
    padding: 0 0 20px; }
    #mega-featured-categories li a:hover {
      color: #000 !important; }
    @media (min-width: 1040px) {
      #mega-featured-categories li a {
        padding: 0 0 5px; } }
  @media (max-width: 1040px) {
    #mega-featured-categories .ui-menu-divider {
      display: none; } }

@media (max-width: 1040px) {
  .megamenu .ui-menu-item.all-category {
    padding: 10px 0 !important;
    width: 100%; }
    .megamenu .ui-menu-item.all-category a {
      padding-left: 0 !important;
      overflow: hidden;
      text-indent: -31px; } }

.nav-sections .nav-sections-items .section-item-content .navigation .megamenu.level0 .level1.parent {
  margin-top: 5px; }

nav.navigation .megamenu.level0 .submenu {
  padding: 0 0 20px; }
  @media (min-width: 1040px) {
    nav.navigation .megamenu.level0 .submenu {
      padding: 0; } }

#wpn-lightbox-content-v3 {
  background: none !important; }
  #wpn-lightbox-content-v3 .weltpixel_newsletter_signup_section {
    margin: 0;
    width: 100%; }

.weltpixel_newsletter__sub-title {
  font-family: "Source Sans Pro", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  margin: -25px 0 25px; }

.wp_newsletter_trigger_button {
  top: auto;
  bottom: 28%;
  margin: 0 0 20px; }

.btt-button {
  right: 45px; }

.product-carousel.block-margin.row {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.product-carousel__heading {
  font-size: 2.2rem;
  font-family: "Questrial", sans-serif;
  margin: 20px 0 40px; }

.product-carousel .product-item-name,
.product-carousel .product-item-name a {
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #303030;
  font-family: "Questrial", sans-serif;
  line-height: 1.7;
  letter-spacing: 1px; }

.product-carousel .product-item-actions {
  display: none; }

.product-carousel .product-item .price-box .price {
  font-size: 1.6rem;
  font-family: "Source Sans Pro", sans-serif; }

.product-carousel .owl-carousel .owl-item img {
  margin-top: 0; }

.product-carousel .owl-carousel.products .owl-stage-outer {
  padding: 0; }

.product-carousel .owl-theme .owl-controls .owl-nav .owl-prev,
.product-carousel .owl-theme .owl-controls .owl-nav .owl-next {
  background: #f1f1f1 !important;
  opacity: 0.6; }

.product-carousel .owl-theme .owl-controls .owl-nav [class*="owl-"] {
  top: 32%; }

.product-carousel .old-price {
  display: block; }

.block .owl-carousel .owl-stage-outer,
.block .products-grid .product-item .product-item-info,
.product-carousel .owl-carousel .owl-stage-outer,
.product-carousel .products-grid .product-item .product-item-info {
  padding: 0; }

.block .products,
.product-carousel .products {
  margin: 0; }

.catalog-product-view .grid.products-grid .owl-item .product-item {
  width: 100% !important;
  margin-left: 0; }

.find-watch {
  background: url("../images/content/find-watch-bg.png") center no-repeat;
  background-size: cover;
  padding: 15px 10px;
  width: 160px;
  display: flex;
  align-items: center; }
  @media (min-width: 428px) {
    .find-watch {
      width: 240px;
      padding: 35px 25px; } }
  .find-watch__inner {
    background: #fff;
    padding: 24px 16px;
    text-align: center;
    margin: 0 auto; }
    .find-watch__inner h4 {
      font-size: 1.5rem;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 600;
      margin: 8px 0 20px; }
    .find-watch__inner p {
      font-size: 1.4rem;
      margin: 0 0 22px;
      letter-spacing: 0.2px; }

.custom-slider--find-watch {
  display: flex; }
  .custom-slider--find-watch .owl-carousel {
    width: calc(100% - 160px); }
    @media (min-width: 428px) {
      .custom-slider--find-watch .owl-carousel {
        width: calc(100% - 240px); } }
  .custom-slider--find-watch .owl-controls {
    display: none; }

.product-item .find-watch {
  width: 100%;
  height: 100%; }

.product-item.find-watch-product {
  max-width: 400px;
  margin: 0 auto;
  background: none;
  box-shadow: none; }

.account .columns {
  padding-top: 35px; }

.form-edit-account .password-info {
  display: none; }

.form-edit-account .actions-toolbar {
  clear: left; }

.page-print.account .page-main .page-title-wrapper .order-date {
  margin: 15px 0; }

.store-view-default.cms-page-view .breadcrumbs, .store-view-default.partexchange-index-index .breadcrumbs {
  display: none; }

.store-view-default.cms-page-view .page-main, .store-view-default.partexchange-index-index .page-main {
  margin: 35px auto;
  width: 100%;
  letter-spacing: 0;
  font-family: sans-serif; }
  .store-view-default.cms-page-view .page-main h1, .store-view-default.partexchange-index-index .page-main h1 {
    font-size: 2.4rem;
    font-family: "bg-bold", sans-serif;
    line-height: 1.4;
    margin: 0 0 35px;
    letter-spacing: 0;
    font-weight: 500;
    text-transform: uppercase; }
  .store-view-default.cms-page-view .page-main h2,
  .store-view-default.cms-page-view .page-main h3, .store-view-default.partexchange-index-index .page-main h2,
  .store-view-default.partexchange-index-index .page-main h3 {
    line-height: 1.4;
    font-family: sans-serif;
    font-weight: 600;
    letter-spacing: 0; }
  .store-view-default.cms-page-view .page-main h2, .store-view-default.partexchange-index-index .page-main h2 {
    font-size: 1.8rem; }
  .store-view-default.cms-page-view .page-main h3, .store-view-default.partexchange-index-index .page-main h3 {
    font-size: 1.4rem; }
  .store-view-default.cms-page-view .page-main p, .store-view-default.partexchange-index-index .page-main p {
    font-size: 1.2rem;
    line-height: 1.4;
    font-family: sans-serif;
    margin: 13px 0;
    text-align: justify; }
    .store-view-default.cms-page-view .page-main p a, .store-view-default.partexchange-index-index .page-main p a {
      text-decoration: underline;
      word-break: break-word; }
  .store-view-default.cms-page-view .page-main table p, .store-view-default.partexchange-index-index .page-main table p {
    text-align: initial; }
  .store-view-default.cms-page-view .page-main ol,
  .store-view-default.cms-page-view .page-main ul, .store-view-default.partexchange-index-index .page-main ol,
  .store-view-default.partexchange-index-index .page-main ul {
    font-size: 1.2rem;
    padding: 0 0 0 25px;
    margin: 20px 0;
    text-align: justify; }
    .store-view-default.cms-page-view .page-main ol h2,
    .store-view-default.cms-page-view .page-main ol h3,
    .store-view-default.cms-page-view .page-main ol h4,
    .store-view-default.cms-page-view .page-main ol h5,
    .store-view-default.cms-page-view .page-main ul h2,
    .store-view-default.cms-page-view .page-main ul h3,
    .store-view-default.cms-page-view .page-main ul h4,
    .store-view-default.cms-page-view .page-main ul h5, .store-view-default.partexchange-index-index .page-main ol h2,
    .store-view-default.partexchange-index-index .page-main ol h3,
    .store-view-default.partexchange-index-index .page-main ol h4,
    .store-view-default.partexchange-index-index .page-main ol h5,
    .store-view-default.partexchange-index-index .page-main ul h2,
    .store-view-default.partexchange-index-index .page-main ul h3,
    .store-view-default.partexchange-index-index .page-main ul h4,
    .store-view-default.partexchange-index-index .page-main ul h5 {
      text-align: left; }
    .store-view-default.cms-page-view .page-main ol li,
    .store-view-default.cms-page-view .page-main ul li, .store-view-default.partexchange-index-index .page-main ol li,
    .store-view-default.partexchange-index-index .page-main ul li {
      margin: 0 0 5px; }
      .store-view-default.cms-page-view .page-main ol li:last-child,
      .store-view-default.cms-page-view .page-main ul li:last-child, .store-view-default.partexchange-index-index .page-main ol li:last-child,
      .store-view-default.partexchange-index-index .page-main ul li:last-child {
        margin: 0; }
  .store-view-default.cms-page-view .page-main .block-collapsible-content ul, .store-view-default.partexchange-index-index .page-main .block-collapsible-content ul {
    padding-left: 0;
    text-align: left; }
  .store-view-default.cms-page-view .page-main .accordion.accordion-border .acctitle, .store-view-default.partexchange-index-index .page-main .accordion.accordion-border .acctitle {
    line-height: 1.5;
    padding-top: 12px;
    padding-bottom: 12px; }
    .store-view-default.cms-page-view .page-main .accordion.accordion-border .acctitle i, .store-view-default.partexchange-index-index .page-main .accordion.accordion-border .acctitle i {
      line-height: 3.2; }
  .store-view-default.cms-page-view .page-main .table, .store-view-default.partexchange-index-index .page-main .table {
    font-size: 1.2rem; }

.store-view-default .cms-part-exchange-a-watch .exchange-part-form,
.store-view-default .partexchange-index-index .exchange-part-form {
  margin: 40px auto 0;
  background: #f9f9f9;
  padding: 15px 4% 35px; }
  @media (min-width: 768px) {
    .store-view-default .cms-part-exchange-a-watch .exchange-part-form,
    .store-view-default .partexchange-index-index .exchange-part-form {
      max-width: 80%; } }
  .store-view-default .cms-part-exchange-a-watch .exchange-part-form .label,
  .store-view-default .partexchange-index-index .exchange-part-form .label {
    font-size: 1.3rem; }

.store-view-default #maincontent.page-main > .page-title-wrapper .page-title {
  display: block;
  text-align: center;
  margin: 15px 0 25px;
  line-height: 1.2; }

.store-view-default .rolex-serials {
  max-width: 980px;
  margin: 0 auto; }
  .store-view-default .rolex-serials p strong {
    font-weight: 600; }
  .store-view-default .rolex-serials h3 {
    margin: 20px 0 15px; }
    @media (min-width: 768px) {
      .store-view-default .rolex-serials h3 {
        margin: 35px 0 25px; } }
  .store-view-default .rolex-serials table {
    margin: 0 0 20px; }
    @media (min-width: 768px) {
      .store-view-default .rolex-serials table {
        margin: 0 0 35px; } }
    .store-view-default .rolex-serials table td {
      width: 50%; }

.store-view-default #ReviewsWidget .Tabs__tab {
  display: none; }
  .store-view-default #ReviewsWidget .Tabs__tab:first-child {
    display: flex; }

.store-view-default .fs-normal-timeline .fs-entry-container {
  width: 50%;
  padding-top: 50%; }
  @media (min-width: 768px) {
    .store-view-default .fs-normal-timeline .fs-entry-container {
      width: 33.3%;
      padding-top: 33.3%; } }
  .store-view-default .fs-normal-timeline .fs-entry-container div.fs-timeline-entry {
    margin: 5px; }

.store-view-default .fs-wrapper div.fs-timeline-entry {
  margin: 5px; }

.store-view-default .store-view-default.cms-customer-reviews .page-main {
  padding: 10px 30px; }

@media (min-width: 768px) {
  .fixed-table {
    table-layout: fixed; } }

.fixed-table .long-text p {
  width: 180px; }
  @media (min-width: 768px) {
    .fixed-table .long-text p {
      width: auto; } }

.table-responsive > .table.fixed-table th,
.table-responsive > .table.fixed-table td {
  white-space: unset; }

.cms-page-view.cms-about-us .page-main {
  text-align: center; }
  .cms-page-view.cms-about-us .page-main .feature-list {
    list-style: none;
    margin: 64px 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    text-align: center; }
    .cms-page-view.cms-about-us .page-main .feature-list.bottom li:nth-child(2) {
      width: 100%;
      margin: 0 0 0 -1px;
      border-top: 0; }
      @media (max-width: 768px) {
        .cms-page-view.cms-about-us .page-main .feature-list.bottom li:nth-child(2) {
          order: 3; } }
      @media (min-width: 768px) {
        .cms-page-view.cms-about-us .page-main .feature-list.bottom li:nth-child(2) {
          width: 33.33%;
          margin: -1px 0 0 -1px;
          border-top: 1px dashed #ddd; } }
    .cms-page-view.cms-about-us .page-main .feature-list li {
      border: 1px dashed #ddd;
      margin: -1px 0 0 -1px;
      width: 50%;
      padding: 14px; }
      @media (min-width: 768px) {
        .cms-page-view.cms-about-us .page-main .feature-list li {
          width: 33.33%;
          padding: 20px; } }
      .cms-page-view.cms-about-us .page-main .feature-list li h3 {
        font-size: 1.3rem;
        font-weight: 600;
        font-family: "Source Sans Pro", sans-serif;
        margin: 10px 0 15px;
        line-height: 1.4;
        text-align: center; }
        @media (min-width: 768px) {
          .cms-page-view.cms-about-us .page-main .feature-list li h3 {
            font-size: 1.6rem; } }
      .cms-page-view.cms-about-us .page-main .feature-list li img {
        width: 90px; }

.about-us__intro {
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif; }
  @media only screen and (min-width: 1040px) {
    .about-us__intro {
      margin: 0 -60px; } }

.about-store {
  display: block;
  width: 100%;
  margin: 64px 0; }

.video-block {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  margin: 32px 0; }
  @media (min-width: 768px) {
    .video-block {
      margin: 64px 0; } }
  .video-block iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.brand-fields {
  display: flex;
  justify-content: space-between; }
  .brand-fields .field {
    width: 47.5%;
    margin: 0 0 20px; }
    .brand-fields .field input,
    .brand-fields .field select {
      background-color: #f7f7f7;
      border: 0; }
    .brand-fields .field select {
      color: #bbb !important; }

.privacy-policy-content > ol {
  list-style: none;
  padding-left: 0 !important; }

.privacy-policy-content ol ol {
  counter-reset: item;
  list-style: none; }
  .privacy-policy-content ol ol li::before {
    display: inline-block;
    content: counters(item, ".") " ";
    counter-increment: item;
    width: 45px;
    margin-left: -45px;
    text-align: center;
    font-weight: bold; }

.terms-title {
  margin-top: 0; }

@media (max-width: 1039px) {
  .privacy-policy {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    left: 0; }
    .privacy-policy-content {
      order: 2; } }

@media (max-width: 1040px) {
  .enquire-form .contact-form-popup {
    width: 100%; } }

.enquire-form__product {
  margin: 0 0 20px;
  padding: 0 25px 0 0 !important;
  display: none;
  text-align: center; }
  @media (min-width: 1040px) {
    .enquire-form__product {
      display: block; } }
  .enquire-form__product-heading {
    font-size: 1.6rem;
    text-align: center;
    font-weight: 600;
    margin: 10px 0 15px; }
  .enquire-form__product-price {
    font-size: 2rem;
    font-weight: 600; }

.enquire-form .modal-popup {
  left: 0; }
  @media (max-width: 768px) {
    .enquire-form .modal-popup.modal-slide {
      left: 0; } }
  .enquire-form .modal-popup .modal-inner-wrap {
    width: 100%;
    max-width: 100%; }
    @media (min-width: 1040px) {
      .enquire-form .modal-popup .modal-inner-wrap {
        max-width: 800px; } }
    .enquire-form .modal-popup .modal-inner-wrap[class] {
      background: #fff; }
  .enquire-form .modal-popup .modal-header {
    padding: 0; }
  .enquire-form .modal-popup .modal-title {
    font-size: 2.2rem;
    margin: 0;
    padding: 15px 35px 10px 25px;
    text-align: center;
    border: 0; }
  .enquire-form .modal-popup .primary {
    width: 100%;
    margin: 0 0 15px; }
  .enquire-form .modal-popup #submit-enquire {
    padding: 10px;
    width: 100%; }

.enquire-form .fieldset {
  margin: 0 0 20px; }

#popup-modal {
  display: none; }

._show #popup-modal {
  display: block; }

.part-exchange-product-popup {
  background: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  display: none;
  height: 100%;
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  margin: 0; }
  .part-exchange-product-popup.modal-custom .action-close {
    margin: 5px; }
    .part-exchange-product-popup.modal-custom .action-close::before {
      padding: 10px; }
  .part-exchange-product-popup > div {
    background-color: #fff;
    box-shadow: 10px 10px 60px #555;
    display: inline-block;
    max-height: 100%;
    max-width: 700px;
    vertical-align: middle;
    width: 100%;
    position: relative;
    padding: 40px 3%;
    overflow-y: auto;
    overflow-x: hidden; }
    @media (min-width: 768px) {
      .part-exchange-product-popup > div {
        margin-top: 1.5%;
        max-height: 95%; } }

.part-exchange-product-popup__heading,
.enquire-form__heading {
  font-size: 1.9rem;
  text-align: left;
  border-bottom: 1px solid #ccc;
  padding: 0 0 10px;
  margin: 20px 0; }

.part-exchange-product-popup__title,
.enquire-form__title {
  margin: 0 0 5px; }

.part-exchange-product-popup .custom-actions-toolbar,
.enquire-form .custom-actions-toolbar {
  margin: 0 0 30px; }

.part-exchange-product-popup__product-name,
.enquire-form__product-name {
  display: block;
  text-align: left; }

.part-exchange-product-popup .field,
.enquire-form .field {
  text-align: left; }

.part-exchange-product-popup input[type="checkbox"],
.enquire-form input[type="checkbox"] {
  top: 0; }

.actions .action.primary {
  line-height: 1;
  padding: 13px 16px;
  font-size: 1.8rem; }

.custom-primary {
  font-size: 1.6rem;
  font-weight: bold; }

.note {
  color: #008000; }

.error,
.valueError {
  color: #ff0000; }

.product-nla {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #c1c1c1;
  background: #f7f7f7;
  padding: 10px;
  margin: 15px 0 20px;
  font-weight: 600; }
  .product-nla__icon {
    font-size: 20px;
    margin: 0 10px 0 0; }

.theme-pearl.catalog-product-view .page-title {
  text-transform: none;
  font-weight: 500;
  line-height: 1.25; }

.theme-pearl.catalog-product-view .product-info-main {
  display: flex;
  flex-direction: column; }
  .theme-pearl.catalog-product-view .product-info-main > div {
    order: 10; }
  .theme-pearl.catalog-product-view .product-info-main .page-title-wrapper {
    order: 1; }
  .theme-pearl.catalog-product-view .product-info-main .product-info-price {
    order: 2; }
  .theme-pearl.catalog-product-view .product-info-main #imega-widget-anchor {
    order: 3;
    margin: 10px 0 5px; }
    @media (max-width: 1040px) {
      .theme-pearl.catalog-product-view .product-info-main #imega-widget-anchor {
        width: 100% !important; } }
  .theme-pearl.catalog-product-view .product-info-main .product-add-form {
    padding-top: 0; }
  .theme-pearl.catalog-product-view .product-info-main .product-social-links .product-addto-links .action {
    margin-right: 0; }
  .theme-pearl.catalog-product-view .product-info-main .product-reviews-summary {
    margin: 10px 0 30px; }
  .theme-pearl.catalog-product-view .product-info-main .box-tocart .qty {
    display: none; }
  .theme-pearl.catalog-product-view .product-info-main .actions {
    width: 100%; }
    @media (min-width: 1040px) {
      .theme-pearl.catalog-product-view .product-info-main .actions {
        width: 85%; } }
  .theme-pearl.catalog-product-view .product-info-main .price-container.tax-excl-active {
    display: flex;
    text-align: center; }
    .theme-pearl.catalog-product-view .product-info-main .price-container.tax-excl-active .price-including-tax::after,
    .theme-pearl.catalog-product-view .product-info-main .price-container.tax-excl-active .price-excluding-tax::after {
      font-size: 1.2rem;
      content: attr(data-label);
      display: block;
      text-align: center;
      margin: 10px 0 0;
      letter-spacing: 0.5px;
      font-weight: 600; }

.theme-pearl.catalog-product-view .product.info.detailed > .items > .item.title a {
  padding: 0 10px; }
  @media (min-width: 1040px) {
    .theme-pearl.catalog-product-view .product.info.detailed > .items > .item.title a {
      padding: 0 20px; } }

.oos-item {
  padding: 0 0 20px; }

.stock.unavailable {
  display: none; }

.product-carousel .products-grid .product-item .product-item-info:hover {
  border: 0 !important;
  box-shadow: none !important; }

.media .wp-product-label-middle-right {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: auto; }

.media .wp-product-label-top-right + .wp-product-label-middle-right {
  top: 75px; }

iframe {
  max-width: 100%; }

.product-top-main {
  padding: 15px 0 0; }

#maincontent .product-info-price .price-box .price-container .price-including-tax,
#maincontent .product-info-price .price-box .price-container .price-excluding-tax {
  margin: 0 25px 0 0; }
  #maincontent .product-info-price .price-box .price-container .price-including-tax::before,
  #maincontent .product-info-price .price-box .price-container .price-excluding-tax::before {
    display: none;
    content: ""; }
  #maincontent .product-info-price .price-box .price-container .price-including-tax .price,
  #maincontent .product-info-price .price-box .price-container .price-excluding-tax .price {
    font-size: 2.6rem;
    line-height: 26px;
    letter-spacing: 0.5px;
    display: block; }
    @media (min-width: 768px) {
      #maincontent .product-info-price .price-box .price-container .price-including-tax .price,
      #maincontent .product-info-price .price-box .price-container .price-excluding-tax .price {
        font-size: 3.1rem;
        line-height: 31px; } }

#maincontent .product-info-price .price-box .special-price .price-container .price {
  color: #C87617 !important; }

#maincontent .product-info-price .price-box .price-excluding-tax .price {
  color: #666 !important; }

#maincontent .product-info-price .price-box .old-price .price-container .price-including-tax {
  margin: 0; }

.theme-pearl.catalog-product-view #maincontent .product-info-main .product-info-price .price-box .price-final_price {
  padding-right: 0; }

.rrp-section {
  margin: 10px 0 0; }

.detailed .product.data.items {
  margin-left: 0;
  margin-right: 0; }

.show-vat {
  margin-top: 15px; }

.category-cms {
  margin: 15px auto 30px; }
  .category-cms img {
    width: 100%;
    display: block; }

div[data-role="compare-products-sidebar"] {
  display: none; }

.product-item .product-item-details .price-box .old-price {
  display: none; }

.theme-pearl.page-products .page-title {
  margin: 20px 0 7px;
  line-height: 1.1; }

@media (max-width: 768px) {
  .theme-pearl.page-products .sorter .sorter-label {
    display: none; }
  .theme-pearl.page-products .sorter #sorter {
    padding: 0 0 2px 5px; } }

@media (max-width: 768px) {
  .theme-pearl.page-products .sidebar {
    margin-bottom: 0 !important; } }

.theme-pearl.page-products button.action.primary.tocart {
  background: #00C5C5;
  border-color: #00C5C5; }
  .theme-pearl.page-products button.action.primary.tocart:hover {
    background: #00acac; }
    .theme-pearl.page-products button.action.primary.tocart:hover span {
      color: #fff; }

.products.products-list .product-item .price-box {
  justify-content: flex-start;
  margin-top: 12px; }

.products.products-list .product-item .product-item-actions {
  text-align: left !important; }

.products.products-list .product-item button.action.primary.tocart {
  margin-right: 20px; }

.products.products-list .product-item .weltpixel-quickview {
  display: none;
  z-index: 9;
  position: absolute;
  text-indent: -10000px;
  width: 33px;
  height: 35px;
  background-image: url("../images/quickview.png");
  background-repeat: no-repeat;
  top: auto;
  left: 200px;
  bottom: 35px; }
  @media (min-width: 768px) {
    .products.products-list .product-item .weltpixel-quickview {
      display: block; } }

.products.products-list .product-item .product-item-description .more {
  display: block;
  color: #00C5C5;
  margin: 2px 0 0; }
  .products.products-list .product-item .product-item-description .more:hover {
    text-decoration: underline;
    color: #00C5C5 !important; }

.products.products-list .product-item .product-item-description iframe {
  display: none; }

.products.products-list .product-item .product-item-description__short-description p {
  margin: 0; }

.product-items .product-item .product-item-name {
  text-transform: uppercase;
  line-height: 0.8; }

.column.main #layer-product-list .products-grid .product-item {
  margin-bottom: 0; }
  @media (min-width: 768px) {
    .column.main #layer-product-list .products-grid .product-item {
      margin: 0 0 20px; } }
  .column.main #layer-product-list .products-grid .product-item .product-item-info {
    padding: 10px 0 10px; }
    @media (min-width: 768px) {
      .column.main #layer-product-list .products-grid .product-item .product-item-info {
        padding: 10px 10px 0; } }
    .column.main #layer-product-list .products-grid .product-item .product-item-info .product-item-name a {
      color: #303030 !important; }
    .column.main #layer-product-list .products-grid .product-item .product-item-info .price-container .price {
      padding: 0; }
    .column.main #layer-product-list .products-grid .product-item .product-item-info .product.photo.product-item-photo {
      margin: 0 0 10px;
      padding: 0 25px; }

.products .product-item .product-item-details .price-box .special-price .price-container .price,
.product-carousel .special-price .price {
  color: #C87617 !important; }

.products-list .product-item-photo {
  padding: 15px 30px 15px 0; }

.products-list .product-item-details {
  padding-top: 10px; }

.product .product-item-link {
  line-height: 1.5;
  hyphens: none; }

.product__brand-name {
  display: block;
  font-family: sans-serif;
  font-weight: 600; }

.product__model {
  margin: 2px 0 6px; }

.product__model, .product__model-number {
  font-size: 1rem;
  letter-spacing: 0.5px;
  display: block; }

.products-grid .product__inner,
.product-carousel .product__inner {
  position: relative;
  min-height: 110px;
  padding: 0 0 25px;
  margin: 0 0 15px; }

.products-grid .product .rrp-section,
.product-carousel .product .rrp-section {
  display: none !important; }

.products-grid .product .price-box,
.product-carousel .product .price-box {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%; }

.product-carousel .product__inner {
  margin: 0; }

.products.products-grid {
  margin: 0; }
  .products.products-grid .products {
    margin: 0; }

.items {
  margin-top: 15px; }
  @media (min-width: 1040px) {
    .items {
      margin-top: 0; } }

.sidebar .swatch-option {
  border: 0; }

.popular-brands {
  margin: 0 0 28px; }
  @media (min-width: 768px) {
    .popular-brands {
      margin: 25px 0 28px; } }
  .popular-brands .block-subtitle {
    font-size: 1.2rem; }
  .popular-brands ul {
    display: flex;
    padding: 0;
    margin: 14px 0 0;
    list-style: none;
    flex-wrap: wrap; }
    .popular-brands ul li {
      margin: 6px 6px 0 0; }
      .popular-brands ul li a {
        font-size: 1.4rem;
        background: #00C5C5;
        color: #fff;
        padding: 5px 12px;
        border-radius: 25px;
        display: block;
        transition: 0.3s ease background; }
        .popular-brands ul li a:hover {
          background: #00acac; }

.block-reorder {
  display: none; }

.filter-options-item.Brands {
  display: none; }

.categorypath-all-brands .filter-options-item.Category {
  display: none; }

.categorypath-all-brands .filter-options-item.Brands {
  display: block; }

#layered-filter-block .filter-content .filter-current ol {
  padding: 5px 10px; }
  @media (min-width: 1040px) {
    #layered-filter-block .filter-content .filter-current ol {
      padding: 5px 0; } }

.toolbar.toolbar-products {
  padding: 10px 0; }

.checkout-container .payment-option-content .form-discount .actions-toolbar .primary .action {
  border: 0;
  height: 40px;
  background: #000;
  color: #fff;
  margin: 0 0 0 5px; }
  .checkout-container .payment-option-content .form-discount .actions-toolbar .primary .action span {
    color: #fff; }

.checkout-container .payment-option-content .form-discount .input-text {
  height: 40px; }

.methods-shipping thead,
.methods-shipping .col-carrier {
  display: none; }

.methods-shipping .table-checkout-shipping-method {
  width: 100%; }

.checkout-index-index .usp-bar {
  display: none; }

.opc-estimated-wrapper {
  margin-top: 15px; }

.page-main .opc-wrapper .form-shipping-address,
.page-main .opc-wrapper .methods-shipping {
  background: #fff; }

#maincontent .hp-v7 .custom-slider {
  padding: 0;
  margin: 0; }

#maincontent .hp-v7 .hp-v7-slider {
  max-width: 1400px;
  margin: 0;
  padding: 0; }
  @media (min-width: 1400px) {
    #maincontent .hp-v7 .hp-v7-slider {
      margin: 0 -130px; } }
  #maincontent .hp-v7 .hp-v7-slider .content_slider {
    height: 100%;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    top: 0;
    justify-content: center;
    padding: 0 55px; }
    @media (min-width: 768px) {
      #maincontent .hp-v7 .hp-v7-slider .content_slider {
        padding: 0 10%; } }
  #maincontent .hp-v7 .hp-v7-slider .banner-title {
    font-size: 1.5rem;
    line-height: 1.2;
    color: #000;
    text-transform: uppercase;
    letter-spacing: 1.15px;
    padding: 0 5px; }
    @media (min-width: 768px) {
      #maincontent .hp-v7 .hp-v7-slider .banner-title {
        font-size: 1.7rem;
        text-align: left; } }
  #maincontent .hp-v7 .hp-v7-slider .banner-description {
    font-size: 2.4rem;
    font-family: "Questrial", sans-serif;
    color: #000;
    text-transform: uppercase;
    padding: 8px 5px 5px;
    margin: 7px 0 0;
    line-height: 1.1; }
    @media (min-width: 768px) {
      #maincontent .hp-v7 .hp-v7-slider .banner-description {
        font-size: 4.8rem;
        text-align: left;
        letter-spacing: 1.5px; } }

.hp-v7-boxes {
  margin-top: 0;
  max-width: 1130px;
  width: 100%;
  padding: 0; }
  .hp-v7-boxes .box-content .content-wrapper h4 {
    font-size: 3.1rem; }
  .hp-v7-boxes .homepage-top-watches img {
    width: calc(100% - 5px) !important;
    margin-left: 5px; }
  .hp-v7-boxes .row .box-content .button-block {
    font-size: 1.6rem;
    height: 60px;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
    padding: 15px;
    margin: 0 0 10px; }
    @media (min-width: 768px) {
      .hp-v7-boxes .row .box-content .button-block {
        font-size: 2.2rem;
        padding: 14.8% 10px;
        height: 0;
        margin: 0 0 20px; } }
    .hp-v7-boxes .row .box-content .button-block:last-child {
      margin: 0; }
    .hp-v7-boxes .row .box-content .button-block span {
      display: block;
      line-height: 1; }
  .hp-v7-boxes .row .box-content.button-block-container {
    margin: 20px 0; }
    @media (min-width: 768px) {
      .hp-v7-boxes .row .box-content.button-block-container {
        margin: 0; } }
  .hp-v7-boxes img {
    width: 100%; }
  .hp-v7-boxes .hcaption {
    position: relative; }
    .hp-v7-boxes .hcaption .content-wrapper {
      padding: 0 35px; }
      .hp-v7-boxes .hcaption .content-wrapper .price {
        text-align: justify; }

.homepage-review {
  text-shadow: 2px 2px 13px #000;
  padding: 0 15px !important; }
  @media (min-width: 768px) {
    .homepage-review {
      padding: 0 35px !important; } }
  .homepage-review__heading {
    font-size: 1.7rem; }
  .homepage-review__content {
    font-size: 1.7rem;
    font-family: "Questrial", sans-serif;
    line-height: 1.15;
    margin: 10px 0; }
    @media (min-width: 768px) {
      .homepage-review__content {
        font-size: 1.9rem;
        margin: 15px 0; } }
  .homepage-review__see-all {
    font-size: 1.6rem;
    font-family: "Questrial", sans-serif;
    line-height: 1.15;
    margin: 10px 0 0;
    text-decoration: underline;
    display: inline-block;
    color: #fff; }
    .homepage-review__see-all:visited, .homepage-review__see-all:active, .homepage-review__see-all:hover {
      text-decoration: underline;
      color: #fff; }
    @media (min-width: 768px) {
      .homepage-review__see-all {
        margin: 25px 0 0; } }
  .homepage-review a * {
    color: #fff; }
  .homepage-review p {
    text-shadow: 2px 2px 13px #000 !important; }

.reviews-box img {
  min-height: 260px; }

@media (max-width: 768px) {
  .hp-v7-boxes .homepage-about-store.box-content .content-wrapper p {
    font-size: 1.7rem; }
  .hp-v7-boxes .homepage-about-store.box-content .content-wrapper h4 {
    font-size: 2.2rem; } }

.homepage-about-store .hcaption {
  position: relative; }
  .homepage-about-store .hcaption a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 50; }

.theme-pearl.store-view-default .brand-title {
  font-size: 1.6rem;
  background: #000;
  color: #fff;
  padding: 0 12px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    .theme-pearl.store-view-default .brand-title {
      font-size: 2.2rem; } }
  .theme-pearl.store-view-default .brand-title::before {
    content: "";
    width: 10px;
    background: #fff;
    position: absolute;
    left: -5px;
    top: 0;
    height: 100%; }
  .theme-pearl.store-view-default .brand-title a {
    color: #fff; }

.homepage-perks {
  display: flex;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  flex-wrap: wrap; }
  .homepage-perks.text {
    text-transform: none;
    font-size: 14px;
    margin: 10px 0 0; }
  .homepage-perks p {
    line-height: 1.25;
    margin: 0; }
  .homepage-perks a {
    color: #000; }
    .homepage-perks a:hover {
      color: #000; }
  .homepage-perks div {
    width: 50%;
    padding: 0 15px; }
  .homepage-perks.col-2 {
    margin: 30px 0 0; }
    .homepage-perks.col-2 div:last-child {
      border-left: 1px solid #ccc; }
    @media (max-width: 768px) {
      .homepage-perks.col-2.bottom div:last-child {
        border: 0; } }
  .homepage-perks.col-4 {
    margin: 30px 0; }
    @media (min-width: 768px) {
      .homepage-perks.col-4 {
        margin-bottom: 40px; } }
    .homepage-perks.col-4 div {
      padding: 5px 15px; }
      @media (min-width: 768px) {
        .homepage-perks.col-4 div {
          width: 25%;
          padding: 0 15px; } }

@media screen and (min-width: 768px) and (max-width: 1040px) {
  #maincontent.page-main .columns .column .hp-v7-boxes .paddingbottom {
    padding-bottom: 10px !important; }
  #maincontent.page-main .columns .column .hp-v7-boxes .paddingright {
    padding-right: 10px !important; }
  #maincontent.page-main .columns .column .hp-v7-boxes .paddingtop {
    padding-top: 10px !important; }
  #maincontent.page-main .columns .column .hp-v7-boxes .paddingleft {
    padding-left: 10px !important; } }

.hp-v7 .mg-bottom-insta .instagram-heading-title {
  margin: 35px 0 30px; }
  .hp-v7 .mg-bottom-insta .instagram-heading-title h3 {
    font-size: 2rem; }
    @media (min-width: 768px) {
      .hp-v7 .mg-bottom-insta .instagram-heading-title h3 {
        font-size: 2.6rem; } }

.owl-carousel .product-item-info {
  padding: 0 10px !important; }

.owl-carousel .product-item-photo {
  display: block;
  padding: 0 20px; }
  @media (min-width: 768px) {
    .owl-carousel .product-item-photo {
      padding: 0 50px; } }

.owl-carousel .product-item-details {
  margin-top: 15px; }

.homepage-blocks a {
  color: #fff; }
  .homepage-blocks a * {
    color: #fff; }

@media (max-width: 768px) {
  .cms-index-index .columns .column.main {
    padding-bottom: 20px; } }

.black-overlay {
  background-color: rgba(0, 0, 0, 0.25); }

.white-overlay {
  background-color: rgba(255, 255, 255, 0.25); }

.fs-slider_v2_5 {
  margin: 0 0 25px; }
  .fs-slider_v2_5 .fs-slider-next-button,
  .fs-slider_v2_5 .fs-slider-prev-button {
    height: 50px;
    padding: 0;
    border-radius: 5px;
    min-width: 50px;
    opacity: 0.75;
    top: 50%;
    transform: scale(0.8);
    margin-top: -25px;
    z-index: 8; }
  .fs-slider_v2_5 .fs-slider-prev-button {
    left: 15px; }
  .fs-slider_v2_5 .fs-slider-next-button {
    right: 15px; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track::before, .slick-track::after {
    content: "";
    display: table; }
  .slick-track::after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.theme-pearl.store-view-default .slick-next,
.theme-pearl.store-view-default .slick-prev {
  border-radius: 3px;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  left: 15px;
  text-indent: 100%;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  font-size: 0;
  z-index: 10;
  border: 0;
  opacity: 0.75;
  margin-top: -20px; }
  .theme-pearl.store-view-default .slick-next::before,
  .theme-pearl.store-view-default .slick-prev::before {
    position: absolute;
    top: 8px;
    left: 7px;
    z-index: 1;
    width: 26px;
    height: 25px;
    content: "";
    background-color: #000;
    opacity: 0.8;
    border-top: 0;
    mask-image: url("../WeltPixel_OwlCarouselSlider/images/arrow.svg"); }
  .theme-pearl.store-view-default .slick-next:hover,
  .theme-pearl.store-view-default .slick-prev:hover {
    border: 0; }

.theme-pearl.store-view-default .slick-next {
  left: auto;
  right: 15px; }
  .theme-pearl.store-view-default .slick-next::before {
    transform: rotate(180deg); }
