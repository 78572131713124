.account .columns {
  padding-top: 35px;
}

.form-edit-account {
  .password-info {
    display: none;
  }

  .actions-toolbar {
    clear: left;
  }
}

.page-print {
  &.account .page-main .page-title-wrapper .order-date {
    margin: 15px 0;
  }
}
