.theme-pearl {
  div.page-wrapper .page-header-v4 .page-header {
    .header_right label {
      padding-right: 0 !important;
    }

    .header.content .logo {
      width: auto;
      z-index: 10;

      @include breakpoint(max-width $mobile) {
        width: 190px;
      }

      img {
        display: block;
        width: auto;
        height: auto;
        margin: 4px auto 0;

        @include breakpoint($mobile) {
          margin: 0 0 0 -100px;
          max-width: 240px;
        }

        @include breakpoint($desktop) {
          margin: 0 auto;
        }
      }
    }
  }
}

.header.panel > .header.links {
  display: none;
  margin: 8px 0 0 !important;

  @include breakpoint($desktop) {
    display: flex;
  }

  .newsletter-btn {
    order: 3;
    margin-right: 0 !important;
  }

  & > li {
    margin: 0 22px 0 0 !important; // overwrite welt important
    letter-spacing: 0;
    color: #656565;

    &.authorization-link {
      &::after {
        margin: 0 -15px 0 2px;
      }
    }

    a {
      text-transform: none;
      color: $turqoise;
    }

    &::after {
      text-transform: none;
    }

    &.customer-welcome {
      margin: -1px 14px 0 -9px !important;
    }
  }
}

.page-wrapper .header.content {
  padding-top: 14px !important;
  padding-bottom: 14px !important;

  @include breakpoint($mobile) {
    padding: 20px 0 !important;
  }
}

.compare.wrapper {
  display: none;
}

.usp-bar {
  background: $black;
  text-align: center;
  transition: 0.3s ease opacity;

  &__slider {
    margin: 0 auto;
    max-width: 86%;
    padding: 0 35px;
    color: $white;

    @include breakpoint ($desktop) {
      max-width: 1140px;
      padding: 0 15px;
    }
  }

  .owl-carousel {
    .owl-item {
      @include fontSize(13px);
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 5px;
    }

    .owl-stage {
      @include breakpoint ($desktop) {
        width: 100% !important; // overwrite inline
        display: flex;
        justify-content: space-between;
      }
    }

    .owl-stage-outer {
      padding: 5px 0;
    }
  }

  .owl-theme .owl-controls .owl-nav [class*="owl-"] {
    background: $black;

    &::before,
    &::after {
      background: $white;
      width: 20px;
      height: 20px;
    }

    &.owl-prev {
      left: -10px;
    }

    &.owl-next {
      right: -10px;
    }
  }

  .owl-thumbs {
    display: none;
  }
}

.usp-hidden {
  opacity: 0;
}

.page-header-v4 .block-search {
  input {
    opacity: 0;
    padding-right: 35px !important; // overwrite important

    @include breakpoint ($desktop) {
      opacity: 1;
    }
  }

  label.active + .control input {
    opacity: 1;
  }
}

.page-wrapper .page-header {
  * {
    text-transform: none;
    letter-spacing: 0.7px;
  }

  .panel.header ul.header.links li::after {
    margin: 0 -13px 0 4px;
  }
}

.theme-pearl .page-wrapper .page-header .panel.header ul.header.links li > a {
  color: $turqoise;

  &:hover {
    color: $turqoise !important;
  }

  &.wp_newsletter_trigger {
    color: $black;
    border: 1px solid $black;
    padding: 1px 5px;

    &:hover {
      color: $black !important;
      text-decoration: none;
    }
  }
}

.weltpixel_quickview-catalog_product-view {
  .usp-bar {
    display: none;
  }

  .page-title-wrapper {
    margin: 15px 0 0;
  }
}

.page-header .header.panel {
  padding-left: 15px;
  padding-right: 15px;
}

.page-header .minicart-wrapper .action.showcart .counter.qty {
  @include breakpoint (max-width $mobileSmall) {
    display: none;
  }
}