.product-nla {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #c1c1c1;
  background: $white-7;
  padding: 10px;
  margin: 15px 0 20px;
  font-weight: 600;

  &__icon {
    font-size: 20px;
    margin: 0 10px 0 0;
  }
}

.theme-pearl.catalog-product-view {
  .page-title {
    text-transform: none;
    font-weight: 500;
    line-height: 1.25;
  }

  .product-info-main {
    display: flex;
    flex-direction: column;

    & > div {
      order: 10;
    }

    .page-title-wrapper {
      order: 1;
    }

    .product-info-price {
      order: 2;
    }

    #imega-widget-anchor {
      order: 3;
      margin: 10px 0 5px;

      @include breakpoint(max-width $desktop) {
        width: 100% !important; // overwrite inline
      }
    }

    .product-add-form {
      padding-top: 0;
    }

    .product-social-links {
      .product-addto-links .action {
        margin-right: 0;
      }
    }

    .product-reviews-summary {
      margin: 10px 0 30px;
    }

    .box-tocart {
      .qty {
        display: none;
      }
    }

    .actions {
      width: 100%;

      @include breakpoint($desktop) {
        width: 85%;
      }
    }

    .price-container {
      &.tax-excl-active {
        display: flex;
        text-align: center;

        .price-including-tax,
        .price-excluding-tax {
          &::after {
            @include fontSize(12px);
            content: attr(data-label);
            display: block;
            text-align: center;
            margin: 10px 0 0;
            letter-spacing: 0.5px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .product.info.detailed > .items > .item.title a {
    padding: 0 10px;
  
    @include breakpoint($desktop) {
      padding: 0 20px;
    }
  }
}

.oos-item {
  padding: 0 0 20px;
}

.stock.unavailable {
  display: none;
}

.product-carousel .products-grid .product-item .product-item-info:hover {
  border: 0 !important;
  box-shadow: none !important;
}

// New & Worn Stickers
.media {
  .wp-product-label-middle-right {
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    height: auto;
  }

  .wp-product-label-top-right + .wp-product-label-middle-right {
    top: 75px;
  }
}

iframe {
  max-width: 100%;
}

.product-top-main {
  padding: 15px 0 0;
}

// Icluding & Excluding Price display
#maincontent .product-info-price .price-box {
  .price-container {
    .price-including-tax,
    .price-excluding-tax {
      margin: 0 25px 0 0;

      &::before {
        display: none;
        content: "";
      }

      .price {
        @include fontSize(26px);
        line-height: 26px;
        letter-spacing: 0.5px;
        display: block;

        @include breakpoint($mobile) {
          @include fontSize(31px);
          line-height: 31px;
        }
      }
    }
  }

  .special-price .price-container .price {
    color: $orange !important;
  }

  .price-excluding-tax .price {
    color: $grey-6 !important;
  }

  .old-price .price-container  .price-including-tax {
    margin: 0;
  }
}

.theme-pearl.catalog-product-view #maincontent .product-info-main .product-info-price .price-box .price-final_price {
  padding-right: 0;
}

.rrp-section {
  margin: 10px 0 0;
}

.detailed .product.data.items {
  margin-left: 0;
  margin-right: 0;
}

.show-vat {
  margin-top: 15px;
}
