div[data-role="compare-products-sidebar"] {
  display: none;
}

.product-item {
  .product-item-details .price-box .old-price {
    display: none;
  }
}

.theme-pearl.page-products {
  .page-title {
    margin: 20px 0 7px;
    line-height: 1.1;
  }

  .sorter {
    @include breakpoint(max-width $mobile) {
      .sorter-label {
        display: none;
      }

      #sorter {
        padding: 0 0 2px 5px;
      }
    }
  }

  .sidebar {
    @include breakpoint(max-width $mobile) {
      margin-bottom: 0 !important; // overwrite welt important
    }
  }

  button.action.primary.tocart {
    background: $turqoise;
    border-color: $turqoise;

    &:hover {
      background: darken($turqoise, 5%);

      span {
        color: $white;
      }
    }
  }
}

.products {
  &.products-list {
    .product-item {
      .price-box {
        justify-content: flex-start;
        margin-top: 12px;
      }

      .product-item-actions {
        text-align: left !important; // overwrite welt important
      }

      button.action.primary.tocart {
        margin-right: 20px;
      }

      .weltpixel-quickview {
        display: none;
        z-index: 9;
        position: absolute;
        text-indent: -10000px;
        width: 33px;
        height: 35px;
        background-image: url("../images/quickview.png");
        background-repeat: no-repeat;
        top: auto;
        left: 200px;
        bottom: 35px;

        @include breakpoint($mobile) {
          display: block;
        }
      }

      .product-item-description {
        .more {
          display: block;
          color: $turqoise;
          margin: 2px 0 0;

          &:hover {
            text-decoration: underline;
            color: $turqoise !important;
          }
        }

        iframe {
          display: none;
        }

        &__short-description {
          p {
            margin: 0;
          }
        }
      }
    }
  }
}

.product-items .product-item .product-item-name {
  text-transform: uppercase;
  line-height: 0.8;
}

.column.main #layer-product-list .products-grid .product-item {
  margin-bottom: 0;

  @include breakpoint($mobile) {
    margin: 0 0 20px;
  }

  .product-item-info {
    padding: 10px 0 10px;
    
    @include breakpoint($mobile) {
      padding: 10px 10px 0;
    }

    .product-item-name {
      a {
        color: #303030 !important;
      }
    }

    .price-container .price {
      padding: 0;
    }

    .product.photo.product-item-photo {
      margin: 0 0 10px;
      padding: 0 25px;
    }
  }
}

.products .product-item .product-item-details .price-box .special-price .price-container .price,
.product-carousel .special-price .price {
  color: $orange !important; // overwrite welt
}

.products-list {
  .product-item-photo {
    padding: 15px 30px 15px 0;
  }

  .product-item-details {
    padding-top: 10px;
  }
}

.product {
  .product-item-link {
    line-height: 1.5;
    hyphens: none;
  }

  &__brand-name {
    display: block;
    font-family: sans-serif;
    font-weight: 600;
  }

  &__model {
    margin: 2px 0 6px;
  }

  &__model,
  &__model-number {
    @include fontSize(10px);
    letter-spacing: 0.5px;
    display: block;
  }
}


.products-grid,
.product-carousel {
  .product {
    &__inner {
      position: relative;
      min-height: 110px;
      padding: 0 0 25px;
      margin: 0 0 15px;
    }

    .rrp-section {
      display: none !important;
    }

    .price-box {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
      width: 100%;
    }
  }
}

.product-carousel .product__inner {
  margin: 0;
}

.products.products-grid {
  margin: 0;

  .products {
    margin: 0;
  }
}

.items {
  margin-top: 15px;

  @include breakpoint($desktop) {
    margin-top: 0;
  }
}
