main[id="maincontent"] {
  .wp-button {
    border: 1px solid $black;
    background-color: transparent;
    text-shadow: none;
    margin: 0;
    text-transform: none;

    span {
      color: $black;
    }

    &:hover {
      background: $black;
      border: 1px solid $black;

      span {
        color: $white;
      }
    }
  }

  .price-container .price-including-tax + .price-excluding-tax {
    display: none;
  }
}


.theme-pearl.store-view-default .action.primary {
  @include fontSize(16px);

  & * {
    @include fontSize(16px);
  }
}
