$black: #000;
$dark-grey: #303030;
$grey-5: #555;
$grey-6: #666;
$grey-c: #ccc;

$white: #fff;
$white-1: #f1f1f1;
$white-7: #f7f7f7;
$white-9: #f9f9f9;
$border: #ddd;
$red: #ff0000;
$green: #008000;
$turqoise: #00C5C5;
$orange: #C87617;
