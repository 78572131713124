#maincontent .hp-v7 {
  .custom-slider {
    padding: 0;
    margin: 0;
  }

  .hp-v7-slider {
    max-width: 1400px;
    margin: 0;
    padding: 0;

    @include breakpoint($desktop-large) {
      margin: 0 -130px;
    }

    .content_slider {
      height: 100%;
      width: 100%;
      left: 0;
      display: flex;
      flex-direction: column;
      top: 0;
      justify-content: center;
      padding: 0 55px;

      @include breakpoint($mobile) {
        padding: 0 10%;
      }
    }

    .banner-title {
      @include fontSize(15px);
      line-height: 1.2;
      color: $black;
      text-transform: uppercase;
      letter-spacing: 1.15px;
      padding: 0 5px;

      @include breakpoint($mobile) {
        @include fontSize(17px);
        text-align: left;
      }
    }

    .banner-description {
      @include fontSize(24px);
      font-family: $questrial;
      color: $black;
      text-transform: uppercase;
      padding: 8px 5px 5px;
      margin: 7px 0 0;
      line-height: 1.1;

      @include breakpoint($mobile) {
        @include fontSize(48px);
        text-align: left;
        letter-spacing: 1.5px;
      }
    }
  }
}

.hp-v7-boxes {
  margin-top: 0;
  max-width: 1130px;
  width: 100%;
  padding: 0;

  .box-content .content-wrapper h4 {
    @include fontSize(31px);
  }

  .homepage-top-watches {
    img {
      width: calc(100% - 5px) !important;
      margin-left: 5px;
    }
  }

  .row .box-content {
    .button-block {
      @include fontSize(16px);
      height: 60px;
      background: $black;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.2;
      padding: 15px;
      margin: 0 0 10px;

      @include breakpoint($mobile) {
        @include fontSize(22px);
        padding: 14.8% 10px;
        height: 0;
        margin: 0 0 20px;
      }

      &:last-child {
        margin: 0;
      }

      span {
        display: block;
        line-height: 1;
      }
    }

    &.button-block-container {
      margin: 20px 0;

      @include breakpoint($mobile) {
        margin: 0; 
      }
    }
  }

  img {
    width: 100%;
  }

  .hcaption {
    position: relative;

    .content-wrapper {
      padding: 0 35px;

      .price {
        text-align: justify;
      }
    }
  }
}

.homepage-review {
  text-shadow: 2px 2px 13px $black;
  padding: 0 15px !important;

  @include breakpoint($mobile) {
    padding: 0 35px !important;
  }

  &__heading {
    @include fontSize(17px);
  }

  &__content {
    @include fontSize(17px);
    font-family: $questrial;
    line-height: 1.15;
    margin: 10px 0;

    @include breakpoint($mobile) {
      @include fontSize(19px);
      margin: 15px 0;
    }
  }

  &__see-all {
    @include fontSize(16px);
    font-family: $questrial;
    line-height: 1.15;
    margin: 10px 0 0;
    text-decoration: underline;
    display: inline-block;
    color: $white;

    &:visited,
    &:active,
    &:hover {
      text-decoration: underline;
      color: $white;
    }

    @include breakpoint($mobile) {
      margin: 25px 0 0;
    }
  }

  a * {
    color: $white;
  }

  p {
    text-shadow: 2px 2px 13px $black !important;
  }
}

.reviews-box {
  img {
    min-height: 260px;
  }
}

.hp-v7-boxes .homepage-about-store.box-content .content-wrapper {
  @include breakpoint (max-width $mobile) {
    p {
      @include fontSize(17px);
    }

    h4 {
      @include fontSize(22px);
    }
  }
}

.homepage-about-store .hcaption {
  position: relative;

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 50;
  }
}

.theme-pearl.store-view-default {
  .brand-title {
    @include fontSize(16px);
    background: $black;
    color: $white;
    padding: 0 12px;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;
    text-transform: uppercase;

    @include breakpoint ($mobile) {
      @include fontSize(22px);
    }

    &::before {
      content: "";
      width: 10px;
      background: $white;
      position: absolute;
      left: -5px;
      top: 0;
      height: 100%;
    }

    a {
      color: $white;
    }
  }
}

.homepage-perks {
  display: flex;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  flex-wrap: wrap;

  &.text {
    text-transform: none;
    font-size: 14px;
    margin: 10px 0 0;
  }

  p {
    line-height: 1.25;
    margin: 0;
  }

  a {
    color: $black;

    &:hover {
      color: $black;
    }
  }

  div {
    width: 50%;
    padding: 0 15px;
  }

  &.col-2 {
    margin: 30px 0 0;

    div {
      &:last-child {
        border-left: 1px solid #ccc;
      }
    }

    &.bottom div {
      @include breakpoint(max-width $mobile) {
        &:last-child {
          border: 0;
        }
      }
    }
  }

  &.col-4 {
    margin: 30px 0;

    @include breakpoint ($mobile) {
      margin-bottom: 40px;
    }


    div {
      padding: 5px 15px;
      @include breakpoint ($mobile) {
        width: 25%;
        padding: 0 15px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1040px) {
  #maincontent.page-main .columns .column .hp-v7-boxes {
    .paddingbottom {
      padding-bottom: 10px !important;
    }

    .paddingright {
      padding-right: 10px !important;
    }

    .paddingtop {
      padding-top: 10px !important;
    }

    .paddingleft {
      padding-left: 10px !important;
    }
  }
}

.hp-v7 .mg-bottom-insta .instagram-heading-title {
  margin: 35px 0 30px;

  h3 {
    @include fontSize(20px);

    @include breakpoint ($mobile) {
      @include fontSize(26px);
    }
  }
}

.owl-carousel {
  .product-item-info {
    padding: 0 10px !important;
  }

  .product-item-photo {
    display: block;
    padding: 0 20px;

    @include breakpoint ($mobile) {
      padding: 0 50px;
    }
  }

  .product-item-details {
    margin-top: 15px;
  }
}

.homepage-blocks a {
  color: $white;

  * {
    color: $white;
  } 
}

.cms-index-index .columns .column.main {
  @include breakpoint(max-width $mobile) {
    padding-bottom: 20px;
  }
}

.black-overlay {
  background-color: rgba(0, 0, 0, 0.25);
}

.white-overlay {
  background-color: rgba(255, 255, 255, 0.25);
}

// Instagram Feed
.fs-slider_v2_5 {
  margin: 0 0 25px;

  .fs-slider-next-button,
  .fs-slider-prev-button {
    height: 50px;
    padding: 0;
    border-radius: 5px;
    min-width: 50px;
    opacity: 0.75;
    top: 50%;
    transform: scale(0.8);
    margin-top: -25px;
    z-index: 8;
  }

  .fs-slider-prev-button {
    left: 15px;
  }

  .fs-slider-next-button {
    right: 15px;
  }
}
